<template>
  <v-container grid-list-md>
    <v-layout wrap>
      <v-flex>
        <v-card elevation="4" :width="1000" class="mx-auto">
          <v-card-title primary-title>
            <h3 class="headline mb-0">
              {{ visit.task ? visit.task.name : "-" }}
            </h3>
          </v-card-title>
          <v-divider></v-divider>
          <div>
            <v-card-text>
              <v-layout wrap>
                <v-flex class="mb-3" xs6 sm6 md6>
                  <b>اسم المهمة</b>
                </v-flex>
                <v-flex class="mb-3" xs6 sm6 md6>
                  <strong>{{ visit.task ? visit.task.name : "" }}</strong>
                </v-flex>

                <v-flex class="mb-3" xs6 sm6 md6>
                  <b>نوع المهمة</b>
                </v-flex>
                <v-flex class="mb-3" xs6 sm6 md6>
                  <strong>زيارة</strong>
                </v-flex>

                <v-flex class="mb-3" xs6 sm6 md6>
                  <b>حالة المهمة</b>
                </v-flex>
                <v-flex class="mb-3" xs6 sm6 md6>
                  <strong>{{
                    visit.task
                      ? visit.task.statusId == 4
                        ? "جديدة"
                        : visit.task.statusId == 3
                        ? "منجزة"
                        : visit.task.statusId == 5
                        ? "بانتظار المراجعة"
                        : visit.task.statusId == 2
                        ? "قيد التقدم"
                        : "لا يوجد"
                      : "لا يوجد"
                  }}</strong>
                </v-flex>
                <v-flex class="mb-3" xs6 sm6 md6>
                  <b>ملاحظات المهمة </b>
                </v-flex>
                <v-flex class="mb-3" xs6 sm6 md6>
                  <strong>{{
                    visit.task
                      ? visit.task.notes != ""
                        ? visit.task.notes
                        : "لا يوجد"
                      : "لا يوجد"
                  }}</strong>
                </v-flex>

                <v-flex class="mb-3" xs6 sm6 md6>
                  <b>تكرار المهمة</b>
                </v-flex>
                <v-flex class="mb-3" xs6 sm6 md6>
                  <strong
                    >{{
                      visit.task
                        ? visit.task.reapetType == "d"
                          ? "يومي"
                          : visit.task.reapetType == "w"
                          ? "إسبوعي"
                          : visit.task.reapetType == "m"
                          ? "شهري"
                          : visit.task.reapetType == "y"
                          ? "سنوي"
                          : "لا يوجد"
                        : "لا يوجد"
                    }}
                  </strong>
                </v-flex>

                <v-flex class="mb-3" xs6 sm6 md6>
                  <b>عدد مرات التكرار</b>
                </v-flex>
                <v-flex class="mb-3" xs6 sm6 md6>
                  <strong>{{
                    visit.task
                      ? visit.task.reapets > 0
                        ? visit.task.reapets
                        : "لا يوجد"
                      : "لا يوجد"
                  }}</strong>
                </v-flex>

                <v-flex class="mb-3" xs6 sm6 md6 v-if="visit.external">
                  <b>نوع الجهة الخارجية</b>
                </v-flex>
                <v-flex class="mb-3" xs6 sm6 md6 v-if="visit.external">
                  <strong>{{
                    visit.external.cementPackage == 1
                      ? "مصنع"
                      : visit.external.cementPackage == 2
                      ? "مشروع"
                      : visit.external.cementPackage == 3
                      ? "مقاول"
                      : visit.external.cementPackage == 4
                      ? "مبسط"
                      : visit.external.cementPackage == 5
                      ? "موقف"
                      : "لا يوجد"
                  }}</strong>
                </v-flex>

                <v-flex class="mb-3" xs6 sm6 md6 v-if="visit.external">
                  <b>اسم الجهة الخارجية</b>
                </v-flex>
                <v-flex class="mb-3" xs6 sm6 md6 v-if="visit.external">
                  <strong>{{ visit.external.name }}</strong>
                </v-flex>

                <v-flex class="mb-3" xs6 sm6 md6 v-if="visit.external">
                  <b>رقم الهاتف</b>
                </v-flex>
                <v-flex class="mb-3" xs6 sm6 md6 v-if="visit.external">
                  <strong>{{ visit.external.mobile }}</strong>
                </v-flex>

                <v-flex class="mb-3" xs6 sm6 md6 v-if="visit.external">
                  <b>الموقع</b>
                </v-flex>
                <v-flex class="mb-3" xs6 sm6 md6 v-if="visit.external">
                  <strong>{{ visit.external.location }}</strong>
                </v-flex>

                <v-flex class="mb-3" xs6 sm6 md6>
                  <b>مصدر المعلومات</b>
                </v-flex>
                <v-flex class="mb-3" xs6 sm6 md6>
                  <strong>{{
                    visit.infoSource ? visit.infoSource : "لا يوجد"
                  }}</strong>
                </v-flex>

                <v-flex class="mb-3" xs6 sm6 md6>
                  <b>المستخدم المنشئ</b>
                </v-flex>
                <v-flex class="mb-3" xs6 sm6 md6>
                  <strong>{{ userCreator }}</strong>
                </v-flex>

                <v-flex class="mb-3" xs6 sm6 md6>
                  <b>المستخدم الموكلة إليه</b>
                </v-flex>
                <v-flex class="mb-3" xs6 sm6 md6>
                  <strong>{{ userResponsible }}</strong>
                </v-flex>

                <v-flex class="mb-3" xs6 sm6 md6>
                  <b>تاريخ البداية</b>
                </v-flex>
                <v-flex class="mb-3" xs6 sm6 md6>
                  <strong>{{
                    visit.task ? createdDate(visit.task.startDate) : "لا يوجد"
                  }}</strong>
                </v-flex>

                <v-flex class="mb-3" xs6 sm6 md6>
                  <b>تاريخ النهاية</b>
                </v-flex>
                <v-flex class="mb-3" xs6 sm6 md6>
                  <strong>{{
                    visit.task ? createdDate(visit.task.endDate) : "لا يوجد"
                  }}</strong>
                </v-flex>

                <v-flex class="mb-3" xs6 sm6 md6>
                  <b>الملاحظات</b>
                </v-flex>
                <v-flex class="mb-3" xs6 sm6 md6>
                  <strong>{{
                    visit.task
                      ? visit.notes != ""
                        ? visit.notes
                        : "لا يوجد"
                      : "لا يوجد"
                  }}</strong>
                </v-flex>

                <v-flex class="mb-3" xs6 sm6 md6>
                  <b>عدد الهدايا</b>
                </v-flex>
                <v-flex class="mb-3" xs6 sm6 md6>
                  <strong>{{ visit.numOfGifts }}</strong>
                </v-flex>

                <v-flex class="mb-3" xs6 sm6 md6>
                  <b>قيمة الهدية</b>
                </v-flex>
                <v-flex class="mb-3" xs6 sm6 md6>
                  <strong>{{ visit.costOfGifts }}</strong>
                </v-flex>
                <!-- <v-flex x12 md12 v-if="isPhotos"
                 >
                 <v-template   v-for="photo in photos"
                :key="photo.idphoto">
                <v-img
                  :src="path + photo.path"
                  aspect-ratio="1"
                  class="grey lighten-2"
                ></v-img>
                </v-template>
          </v-flex> -->
                <v-flex>
                  <v-row>
                    <v-col
                      v-for="photo in visit.photos"
                      :key="photo.idphoto"
                      class="d-flex child-flex"
                      cols="4"
                    >
                      <v-img
                        :src="path + photo.path"
                        aspect-ratio="1"
                        class="grey lighten-2"
                      >
                        <template v-slot:placeholder>
                          <v-row
                            class="fill-height ma-0"
                            align="center"
                            justify="center"
                          >
                            <v-progress-circular
                              indeterminate
                              color="grey lighten-5"
                            ></v-progress-circular>
                          </v-row>
                        </template>
                      </v-img>
                    </v-col>
                  </v-row>
                </v-flex>
              </v-layout>
            </v-card-text>
            <v-flex
              xs12
              md12
              v-if="
                visit.productNeedVisits
                  ? visit.productNeedVisits.length > 0
                  : false
              "
            >
              <v-divider></v-divider>
              <b class="text-center">المنتجات</b>
              <v-divider></v-divider>
              <v-simple-table fixed-header>
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th class="text-center"><b>اسم المنتج</b></th>
                      <th class="text-center"><b>السعر</b></th>
                      <th class="text-center"><b>الاحتياج اليومي</b></th>
                      <th class="text-center"><b>الاحتياج الشهري</b></th>
                      <th class="text-center"><b>الشركة</b></th>
                      <th class="text-center"><b>الناقل</b></th>
                    </tr>
                  </thead>
                  <tbody v-if="visit.productNeedVisits.length > 0">
                    <tr
                      v-for="(item, index) in visit.productNeedVisits"
                      :key="index"
                    >
                      <td class="text-center">
                        {{ item.prodName ? item.prodName : "-" }}
                      </td>
                      <td class="text-center">
                        {{ item.price ? item.price : "-" }}
                      </td>
                      <td class="text-center">
                        {{ item.dneed ? item.dneed : "-" }}
                      </td>
                      <td class="text-center">
                        {{ item.mneed ? item.mneed : "-" }}
                      </td>
                      <td class="text-center">
                        {{ item.comp.name ? item.comp.name : "-" }}
                      </td>
                      <td class="text-center">
                        {{ item.transporter ? item.transporter.name : "-" }}
                      </td>
                    </tr>
                  </tbody>
                  <tbody v-else>
                    <tr>
                      <td
                        colspan="7"
                        class="text-center"
                        style="color: gray; font-size: 12px"
                      >
                        لا توجد بيانات لعرضها
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-flex>
            <v-divider></v-divider>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                v-if="
                  visit.task
                    ? (role == 'Supervisor' || role == 'Admin') &&
                      visit.task.statusId == 5
                    : false
                "
                style="background: #58dd5b; color: white; margin-left: 5px"
                @click="acceptVisit"
              >
                <b>تأكيد</b>
              </v-btn>
              <v-btn
                v-if="
                  visit.task
                    ? visit.task.creatorId == user.id ||
                      visit.task.responsibleId == user.id ||
                      role == 'Admin'
                    : false
                "
                style="background: gray; color: white; margin-left: 5px"
                @click="
                  $router.push({
                    name: 'chatting',
                    params: {
                      taskId: visit.taskId ? visit.taskId : visit.task.idtask,
                    },
                  })
                "
              >
                <b>المحادثة</b>
              </v-btn>
              <!--  Condition for send report
                             v-if="visit.task.ResponsibleId ==user.id &&  role=='Salesman' "
                            -->
              <v-btn
                v-if="
                  visit.task
                    ? visit.task.statusId != 3 &&
                      visit.task.statusId != 5 
                     //&& visit.task.responsibleId == user.id
                    : false && (role == 'Salesman' ||role == 'Admin') 
                "
                style="background: #58dd5b; color: white; margin-left: 5px"
                @click="
                  $router.push({
                    name: 'create-visit-report',
                    params: { visitId: visit.idvisit, task: visit },
                  })
                "
              >
                <b>إنشاء تقرير بالزيارة</b>
              </v-btn>
                   <v-btn   v-if="
                  ( visit.task ?  visit.task.statusId != 3 && visit.task.statusId != 5 && visit.task.userCreator == user.id : false) ||
                  ( visit.task ?  visit.task.statusId != 3 && visit.task.statusId != 5  :false &&
                  role == 'Admin')
                "
                                style="background:#5867dd;color:white;margin-left:5px;"
                                @click="$router.push({name:'edit-visit',params: { visit: visit }})">
                                <b> تعديل</b>
                            </v-btn>
              <v-btn @click="$router.go(-1)" style="background: gray" dark>
                <b>رجوع</b>
              </v-btn>
            </v-card-actions>
          </div>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      transporterName: "",
      userCreator: "",
      userResponsible: "",
      visit: {
        Name: "",
        TypeId: "",
        ResponsibleId: "",
        CreatorId: "",
        StatusId: "",
        Notes: "",
        StartDate: "",
        EndDate: "",
        ReapetType: "",
        Reapets: "",
        photos: null,
      },
      recId: "",
      users: [],
      // ,photos:null
      // ,isPhotos :false,
      path: "https://salestools.yamamacement.com/",
    };
  },
  methods: {
    getUsersName() {
      this.users = [];
      this.ApiService.get("auth/listusers")
        .then((res) => {
          this.users = res.data;
          this.users.forEach((el) => {
            if (el.user.id == this.visit.task.creatorId) {
              this.userCreator = el.user.fullName;
            }
            if (el.user.id == this.visit.task.responsibleId) {
              this.recId = el.user.id;
              this.userResponsible = el.user.fullName;
            }
          });
        })
        .catch(() => {});
    },
    createdDate(date) {
      const current_datetime = new Date(date);
      return current_datetime.toLocaleDateString("en-US");
    },
    getTransporter(id) {
      this.ApiService.get("Transporters/getalltransporters")
        .then((res) => {
          var allTransporters = res.data.responseData;
          allTransporters.forEach((el) => {
            if (el.idtransporter == id) {
              this.transporterName = el.name;
            }
          });
        })
        .catch(() => {});
    },
    async getVisit(id) {
      await this.ApiService.get("Visit/GetVisitbyID?id=" + id)
        .then((res) => {
          // this.path = "https://salestools.yamamacement.com/";
          // console.log(res.data.responseData[0]);
          this.visit = res.data.responseData[0];
          // if (this.visit.photos != null || this.visit.photos.length > 0) {
          //    this.isPhotos = true;
          //     this.photos = this.visit.photos;
          //   }
        })

        .catch(() => {
          this.$router.go(-1);
        });
    },
    acceptVisit() {
      this.ApiService.put("task/confirmTask/" + this.visit.task.idtask)
        .then(() => {
          let msg =
            "تم تأكيد الزيارة" +
            this.visit.task.name +
            "من قبل " +
            this.user.fullName;
          this.$socket.invoke(
            "NewStatus",
            this.recId,
            this.user.id,
            msg,
            this.visit.task.statusId,
            3,
            this.visit.task.idtask
          );
          this.hel.showSnackMsg("تمت العملية بنجاح", "#58dd5b", 2000);
          this.$router.go(-1);
        })
        .catch(() => {
          this.hel.showSnackMsg("فشلت العملية", "#af1616", 2000);
        });
    },
  },
  created() {
    this.user = JSON.parse(window.localStorage.getItem("authUser"));
    this.role = window.localStorage.getItem("roles");
    this.$route.params.visit
      ? (this.visit = this.$route.params.visit)
      : this.getVisit(this.$route.params.visitId);
    this.getUsersName();
    this.getTransporter(this.visit.transporterId);
  },
};
</script> 

<style scoped>
b,
strong {
  font-size: 14px;
}
</style>